<template>
    <admin-dashboard-layout>
        <v-row>
            <CustomTable
                :headers="headers"
                :items="nftokens"
                @edit="edit"
                @destroy="destroy"
            />
        </v-row>
        <v-row v-if="pag" justify="center">
            <template v-for="(link, i) in pag.links">
                <v-btn
                    v-if="Number.isInteger(link.label)"
                    outlined
                    x-small
                    class="mx-1"
                    color="secondary"
                    :disabled="link.active"
                    :key="i"
                    @click="
                        setTokens({ page: link.label, status: 'submitted' })
                    "
                >
                    {{ link.label }}
                </v-btn></template
            >
        </v-row>
        <v-dialog
            v-model="dialog.open"
            scrollable
            max-width="600px"
            v-if="nftoken"
        >
            <v-card v-if="dialog.title == 'Edit'" color="primary">
                <v-card-title>
                    {{ dialog.title }}
                </v-card-title>
                <v-card-text>
                    <NftokenEditForm @change="dialog.action" />
                </v-card-text>
            </v-card>
            <v-card v-else-if="dialog.title == 'Delete'" color="primary">
                <v-card-title>
                    {{ dialog.title }}
                </v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark @click="dialog.action"> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </admin-dashboard-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "AdminIndex",
    components: {
        AdminDashboardLayout: () => import("@/layouts/AdminDashboardLayout"),
        NftokenEditForm: () => import("@/components/NftokenEditForm.vue"),
    },
    data: function () {
        return {
            dialog: {
                open: false,
                title: "",
                action: () => {},
            },
        };
    },
    computed: {
        ...mapGetters({
            nftoken: "nftoken/item",
            nftokens: "nftoken/allItems",
            pag: "nftoken/pagination",
        }),
    },
    methods: {
        ...mapActions({
            setTokens: "nftoken/setAllItems",
            setToken: "nftoken/setItem",
            storeToken: "nftoken/storeItem",
            updateToken: "nftoken/updateItem",
            destroyToken: "nftoken/destroyItem",
            setDefaultToken: "nftoken/setDefaultItem",
        }),
        edit(index) {
            this.setToken(this.nftokens[index].id);
            this.dialog.open = true;
            this.dialog.title = "Edit";
            this.dialog.action = this.handleUpdate;
        },
        destroy(index) {
            this.setToken(this.nftokens[index].id);
            this.dialog.open = true;
            this.dialog.title = "Delete";
            this.dialog.action = this.handleDelete;
        },
        async handleUpdate(next) {
            await this.updateToken();
            next();
            this.setTokens({ status: "submitted" });
        },
        async handleDelete() {
            await this.destroyToken();
            this.setTokens({ status: "submitted" });
            this.dialog.open = false;
        },
    },
    created() {
        this.$store.commit("nftoken/setAllItems", []);
        this.setTokens({ status: "submitted" });

        this.headers = [
            { text: "Email", value: "email" },
            { text: "Type", value: "type" },
            { text: "Title", value: "title" },
            { text: "Is selling", value: "is_sale_on" },
            { text: "Date", value: "sale_date" },
            { text: "Price", value: "sale_price" },
            { text: "Total supply", value: "total_supply" },
            { text: "Status", value: "status" },
        ];
    },
};
</script>

<style scoped>
</style>
